import {Autocomplete, Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import './AddBook.scss'
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {Dayjs} from "dayjs";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import MessageContext from "../MessageContext";
import {useKeycloak} from "@react-keycloak/web";
import {ProfileContext} from "../ProfileContext";
import ReactGA from "react-ga4";


export default function AddBook() {
    const [lockedParishId, setLockedParishId] = useState<number| null>(null);

    const location = useLocation();

    useEffect(() => {
        ReactGA.send(location.pathname + location.search);
    }, [location]);

    const router = useNavigate()

    const [prefix, setPrefix] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [birthYear, setBirthYear] = useState("")
    const [deathYear, setDeathYear] = useState("")
    const [deathDate, setDeathDate] = useState<String | null>(null)
    const [parishId, setParishId] = useState(lockedParishId)
    const [introduction, setIntroduction] = useState("")

    const [fileName, setFileName] = useState("")
    const [file, setFile] = useState<any | null>(null)
    const [parishQuery, setParishQuery] = useState("")
    const [parishList, setParishList] = useState<any>([])

    const setMessage = useContext(MessageContext);
    const profile = useContext(ProfileContext);

    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        if(initialized) {
            let isParish = !isNaN(+profile.attributes.schematyzm_id) && profile.attributes.schematyzm_id > 0;
            if (isParish) {
                setLockedParishId(+profile.attributes.schematyzm_id);
                setParishId(+profile.attributes.schematyzm_id);
            }
        }
    }, [initialized, profile]);

    function send(e: any) {
        e.preventDefault()
        let data = {
            "prefix": prefix,
            "firstName": firstName,
            "lastName": lastName,
            "birthYear": birthYear,
            "deathYear": deathYear,
            "deathDate": deathDate,
            "introduction": introduction,
            "photoBase64": file,
            "parishId": parishId
        }
        axios.post(process.env.REACT_APP_MEMENTO_SERVER_URL + 'api/v1/books', data, {headers: {'Authorization': 'Bearer ' + keycloak.token}})
            .then(d => {
                setMessage("Utworzono nową księgę")
                router("/")
            }).catch(d => {
            setMessage("Błąd podczas tworzenia księgi")
        })
    }

    function getBase64(file: any) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setFile(reader.result)
        };
        reader.onerror = function (error) {
            setFile("")
        };
    }

    function onChangeFile(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) {
            return;
        }
        const data = e.target.files[0];
        getBase64(data)
        const {name} = data;
        setFileName(name)
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_MEMENTO_SERVER_URL + "api/v1/parishes?query=" + parishQuery, {headers: {'Authorization': 'Bearer ' + keycloak.token,}}).then(resp => {
            setParishList(resp.data)
        })
    }, [parishQuery]);

    return (
        <>
            <div className="PageHeader">
                <Container>
                    <h2>Dodawanie księgi</h2>
                </Container>
            </div>
            <Container className="NowaKsiegaWrapper">
                <form onSubmit={send}>
                    <p>*1. Proszę wpisać tytuł księgi</p>
                    <Grid container columns={10} justifyItems="center" justifyContent="center" alignContent="center"
                          alignItems="center" spacing={1}>
                        <Grid item md={2} xs={10}><TextField onChange={e => setPrefix(e.target.value)}
                                                             variant="outlined" label="Przedrostek" fullWidth/></Grid>
                        <Grid item md={2} xs={5}><TextField onChange={e => setFirstName(e.target.value)}
                                                            variant="outlined" label="Imię" required fullWidth/></Grid>
                        <Grid item md={2} xs={5}><TextField onChange={e => setLastName(e.target.value)}
                                                            variant="outlined" label="Nazwisko" required
                                                            fullWidth/></Grid>
                        <Grid item md={2} xs={3}><TextField type="number" onChange={e => setBirthYear(e.target.value)}
                                                            variant="outlined" label="Rok urodzenia" required
                                                            fullWidth/></Grid>
                        <Grid item md={2} xs={3}><TextField type="number" onChange={e => setDeathYear(e.target.value)}
                                                            variant="outlined" label="Rok śmierci" required fullWidth/></Grid>
                    </Grid>
                    <p>2. Proszę wpisać dokładną datę śmierci w formacie dd-mm-rrrr
                        (będzie wtedy możliwe umieszczenie w kalendarium)</p>
                    <DatePicker label="Data śmierci"
                                onChange={e => setDeathDate(e != null ? (e as Dayjs).format('YYYY-MM-DD') : null)}
                                format="DD.MM.YYYY"
                    />

                    <p>*3. Proszę wpisać nagłówek księgi (krótka notka biograficzna, nekrolog):</p>
                    <TextField onChange={e => setIntroduction(e.target.value)} fullWidth label="Nagłówek księgi"
                               variant="outlined" required multiline rows={5}/>
                    <p>*4. Proszę dodać fotografię dla tej księgi.</p>
                    <Button variant="contained" component="label">
                        {!fileName && <>Wybierz plik z dysku</>}
                        {fileName && <>{fileName}</>}
                        <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            hidden
                            onChange={onChangeFile}
                            required
                        /></Button>
                    <p>*5. Proszę wskazać parafię, z którą ta księga będzie związana (proboszcz tej parafii będzie
                        opiekunem
                        księgi i będzie mógł akceptować wpisy do tej księgi).</p>
                    {lockedParishId && <p>Zalogowana parafia - {localStorage ? localStorage.getItem('login') : ""}</p>}
                    {!lockedParishId && <Autocomplete
                        filterOptions={(x) => x}
                        onInputChange={e => {
                            setParishQuery((e.target as HTMLInputElement).value)

                        }}
                        onChange={(e, v: any) => setParishId(v ? v.id : 0)}
                        noOptionsText="Nie znaleziono"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label="Parafia"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />)}
                        getOptionLabel={(o: any) => o.name + " - " + o.address}
                        renderOption={(props, data: any) => <li {...props}>
                            <Grid container>
                                <Grid item>
                                    <span>{data.name}</span>
                                    <Typography variant="body2" color="text.secondary">{data.address}</Typography>
                                </Grid>
                            </Grid>
                        </li>}
                        options={parishList}/>}
                    <FormControlLabel required control={<Checkbox/>}
                                      label="potwierdzam, że zapoznałem się z regulaminem ...................."/>
                    <FormControlLabel required control={<Checkbox/>}
                                      label="wyrażam zgodę na publikację mojego wpisu na licencji ....................."/>
                    <p>6. Proszę zapisać księgę (po zapisaniu księga będzie natychmiast widoczna w serwisie i będzie
                        można
                        do niej dodawać wpisy).</p>
                    <Button variant="outlined" size="large" type="submit">Zapisz</Button>
                </form>
            </Container>
        </>
    )
}